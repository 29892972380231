<template>
  <div>
    <el-container>
      <el-aside>
        <el-row class="tac">
          <el-col :span="20">
            <el-menu
              :default-active="default_active_index"
              class="el-menu-vertical-demo"
              @select="handleSelect"
              ref="menu"
            >
              <el-submenu index="1">
                <template #title>
                  <i class="el-icon-menu"></i>
                  <span>怪物猎人</span>
                </template>
                <el-menu-item-group>
                  <template #title>冰原</template>
                  <el-menu-item index="1-1">金火龙_锤子_TA规则</el-menu-item>
                  <el-menu-item index="1-2">银火龙_锤子_TA规则</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template #title>
                  <i class="el-icon-menu"></i>
                  <span>只狼</span>
                </template>
                <el-menu-item-group>
                  <template #title>一周目</template>
                  <el-menu-item index="2-1">义父_无伤</el-menu-item>
                  <el-menu-item index="2-2">巨型忍者_枭_无伤</el-menu-item>
                  <el-menu-item index="2-3">剑圣_苇名一心_无伤</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                <template #title>
                  <i class="el-icon-menu"></i>
                  <span>仁王</span>
                </template>
                <el-menu-item-group>
                  <template #title>仁王2</template>
                  <el-menu-item index="3-1">浅井长政_纯立回</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-main>
        <el-card class="box-card" >
          <template #header>
            <div class="card-header">
              <span v-if="video_index_url!=null">{{video_index_url[selected_index]['discribe']}}</span>
            </div>
          </template>
          <video width="1280" height="720" v-if="video_index_url!=null" :src="video_index_url[selected_index]['url']" controls></video>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
        default_active_index: "1-1",
        video_index_url: null,
        selected_index: "1-1",
        video_data: {}
    }
  },
  beforeMount(){
    this.axios.get(process.env.VUE_APP_API+'v1/videos')
      .then(response => {
        this.video_data = response.data
        
        this.video_index_url = {}
        var count = 1
        for(var category in this.video_data) {
          for(var tag in this.video_data[category]) {
            for(var index in this.video_data[category][tag]) {
              var video = this.video_data[category][tag][index]
              this.video_index_url[video["index"]] = { "url": process.env.VUE_APP_RES + "videos/" + video["filename"], "discribe": video["discribe"] }
            }
          }
          this.$refs.menu.open(count);
          count++
        }
      })
  },
  mounted(){
    if(this.$route.query.index != null) {
      this.default_active_index = this.$route.query.index;
      this.selected_index = this.$route.query.index;
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.selected_index = keyPath[1];
      console.log(this.selected_index);
    }
  }
};
</script>

<style scoped>
.el-menu {
  text-align: left;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  max-width: 1320px;
}

video {
	object-fit:contain;
  width: 100%;
  height: 100%;
}

.el-main {
  margin-left: 0px;
}
</style>